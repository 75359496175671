<template>
  <div class="h-full">
    <div class="bg-mask"></div>
    <div class="bg-circles"></div>
    <div class="relative overflow-y-scroll scrollbar-hide h-full m-auto mx-4 z-10">
      <div class="back-btn-wrapper" style="position: relative; right : -24px">
        <button class="back-btn" @click="()=> this.$router.go(-1)">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="43" viewBox="0 0 43 43">
            <defs>
              <filter id="Rectangle_3158" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse">
                <feOffset dy="3" input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="3" result="blur"/>
                <feFlood flood-opacity="0.161"/>
                <feComposite operator="in" in2="blur"/>
                <feComposite in="SourceGraphic"/>
              </filter>
            </defs>
            <g id="back" transform="translate(-308 -28)">
              <g id="Group_12962" data-name="Group 12962">
                <g id="Group_4717" data-name="Group 4717" transform="translate(317 33.547)">
                  <g id="Group_4426" data-name="Group 4426" transform="translate(0 0.452)">
                    <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectangle_3158)">
                      <rect id="Rectangle_3158-2" data-name="Rectangle 3158" width="25" height="25" rx="12.5" transform="translate(9 6)" fill="#ff7400"/>
                    </g>
                  </g>
                </g>
                <path id="ic_chevron_right_24px" d="M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z" transform="translate(317.705 34.376)" fill="#fff"/>
              </g>
            </g>
          </svg>
        </button>
      </div>
      <div class="comment_wrapper mt-4 mb-48" >
        <p class="header_comment">در خواست ها</p>
        <div class="comment" v-for="(item ,i) in list" :key="i">
          <img v-if="item.type === 'ad-request'" :src="baseUrl +item.connectable.profile" alt="">
          <div class="comment__detail">
            <h6 v-if="item.type === 'ad-request'">{{
                item.connectable.first_name + ' ' + item.connectable.last_name
              }}</h6>
            <p v-if="item.type === 'ad-request'" class="my-4 text-white">{{ item.notifiable.description }}</p>
            <div class="flex justify-between items-center ">
              <p v-if="item.type === 'ad-request'">{{ item.created_at }}</p>
              <div class="pb-2  text-center w-full" v-else>
                <p class="mb-2">مدیر سیستم</p>
                <h6>{{ item.text }}</h6>
                <p v-if="item.meta">به دلیل : {{ item.meta.reason }}</p>
              </div>
              <router-link v-if="item.type === 'ad-request'"
                           :to="{name : 'NotificationsDetailRealEstate' ,params : {id : item.notifiable_id}}"
                           id="read-more">اطلاعات بیشتر
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <infinite v-if="page === 2" @infinite="infiniteHandler">
        <div class="text-white text-center mt-16" slot="no-results">اعلان دیگری وجود ندارد</div>
        <div class="text-white text-center mt-16" slot="no-more"></div>
      </infinite>
    </div>
  </div>
</template>

<script>
import axios from "../../plugins/axios";
import infinite from 'vue-infinite-loading'

export default {
  name: "NotificationsRealEstate",
  components: {
    infinite
  },
  data() {
    return {
      page: 1,
      list: [],
      data: {
        connectable: {},
      },
      baseUrl: this.$store.state.general.baseURL
    }
  },
  methods: {
    infiniteHandler($state) {
      if (this.page === 2){
        axios.get('/notifications', {
          params: {
            page: this.page
          }
        }).then(({data}) => {
          if (data.data.data.length) {
            this.page += 1;
            this.list.push(...data.data.data);
            $state.loaded();
          } else {
            $state.complete();
          }
        })
      }

    }
  },
  mounted() {
    axios.get('/notifications', {
      params: {
        page: this.page
      }
    }).then(({data}) => {
        this.page = 2
        this.list = data.data.data;
    })
  }
}
</script>

<style scoped>
#read-more {
  background: #644B92;
  border-radius: 0.8rem;
  font-size: 11px;
  text-align: center;
  padding: 0.5rem 0.5rem;
  border: 1px solid #FF7400;
}

</style>